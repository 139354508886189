import { LOCATION_WORK } from '../api'
import { METHOD, request } from '@/utils/request'

// 获取合同主体列表
export async function getContractSubjectList(params) {
  return request(`${LOCATION_WORK}/contractCompany/getList`, METHOD.GET, params)
}

// 新增合同主体
export async function createContractSubject(params) {
  return request(`${LOCATION_WORK}/contractCompany/createContractCompany`, METHOD.POST, params)
}

// 修改合同主体
export async function updateContractSubject(params) {
  return request(`${LOCATION_WORK}/contractCompany/updateContractCompany`, METHOD.POST, params)
}

// 删除合同主体
export async function deleteContractSubject(params) {
  return request(`${LOCATION_WORK}/contractCompany/deleteContractCompany`, METHOD.POST, params)
}
// 更新租户合同公司状态
export async function changeContractStatus(params) {
  return request(`${LOCATION_WORK}/contractCompany/changeStatus`, METHOD.POST, params)
}
